import * as React from "react"
import { Link, HeadFC } from "gatsby"
import { useLottie } from "lottie-react";

import Layout from "../components/layout"

import falldown from "../components/notfound/404.json"

const NotFoundPage = () => {


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: falldown,
    rendererSettings: {      
      viewBoxSize: '0 0 512 512',          
    },
  };

  const { View: fallAnimation } = useLottie(defaultOptions);

  return (
    <Layout>
      <div className="flex min-h-full flex-col bg-white pt-16 pb-12">
        <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
          
          <div className="py-16">
            <div className="text-center">
              <p className="text-base font-semibold text-pink-600">404</p>
              <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found.</h1>
              <p className="mt-2 text-base text-gray-500">Sorry, we couldn’t find the page you’re looking for.</p>
              <div className=" m-auto w-1/4 ">
                {fallAnimation}
              </div>            
            </div>
          </div>
        </main>     
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const Head: HeadFC = () => <title>Not found</title>
